import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import { BookingStatus } from '@/calendesk/models/DTO/Response/BookingStatus'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import SimpleStoreProductCreatePaymentRequestData
  from '@/calendesk/models/DTO/Request/SimpleStoreProductCreatePaymentRequestData'
import CreatePaymentForBookingsRequestData from '@/calendesk/models/DTO/Request/CreatePaymentForBookingsRequestData'
import { resetApp } from '@/calendesk/api/ResetApp'

export default Vue.extend({
  data () {
    return {
      isMakingPayment: false
    }
  },
  computed: {
    ...mapGetters({
      stripe: 'stripe/getStripe',
      appConfiguration: 'setup/getAppConfiguration'
    }),
    minimumPayment (): number {
      switch (this.appConfiguration.currency.toLowerCase()) {
        case 'pln':
          return 200
        default:
          return 100
      }
    },
    paymentsActive (): boolean {
      return !!this.stripe
    }
  },
  methods: {
    ...mapActions({
      createPaymentForBooking: 'stripe/createPaymentForBooking',
      createPaymentForBookings: 'stripe/createPaymentForBookings',
      createPaymentForSimpleStoreProduct: 'stripe/createPaymentForSimpleStoreProduct'
    }),
    canPayForBookings (bookings: Array<Booking>): boolean {
      if (this.stripe) {
        const result = this.getBookingsForPayment(bookings)

        return (result.length > 0)
      }

      return false
    },
    getBookingsForPayment (bookings: Booking[]): Booking[] {
      return bookings.filter((booking) => {
        if (booking.service && booking.service.allowOnlinePayment && ((booking.service && booking.service.price >= this.minimumPayment) || (booking.serviceType && booking.serviceType.price >= this.minimumPayment))) {
          if ((booking.status === BookingStatus.PAYMENT ||
            (booking.status === BookingStatus.APPROVED && !booking.paid))) {
            return true
          }
        } else if (booking.simpleStoreProducts && booking.simpleStoreProducts.length > 0) {
          return true
        }

        return false
      })
    },
    payBookings (bookings: Array<Booking>, promoCode: string | null) {
      this.isMakingPayment = true
      const bookingIds = bookings.map((booking) => booking.id)
      this.createPaymentForBookings(new CreatePaymentForBookingsRequestData(bookingIds, promoCode)).then((sessionId) => {
        this.stripe.redirectToCheckout({
          sessionId: sessionId
        }).then((result: any) => {
          errorNotification(null, result, false)
          this.isMakingPayment = false
        })
      }).catch((error) => {
        if (promoCode && error.response && error.response.status === 400) {
          errorNotification('promo_code_invalid_message', error, false)
        } else if (error.response && error.response.data && error.response.data.code === 'P24_INACTIVE') {
          errorNotification('p24_inactive', error, false)
        } else {
          errorNotification(null, error)
        }

        this.isMakingPayment = false
      })
    },
    paySimpleStoreProduct (request: SimpleStoreProductCreatePaymentRequestData): Promise<void> {
      return new Promise((resolve) => {
        if (this.stripe) {
          this.isMakingPayment = true
          this.createPaymentForSimpleStoreProduct(request).then((sessionId) => {
            this.stripe.redirectToCheckout({
              sessionId: sessionId
            }).then((result: any) => {
              errorNotification(null, result)
              this.isMakingPayment = false
              resolve()
            })
          }).catch((error) => {
            if ((error.response && error.response.data && error.response.data.errors && error.response.data.errors.email)) {
              errorNotification('email_is_taken', error, false)
            } else if (error && error.response && error.response.status && error.response.data && error.response.data.code) {
              switch (error.response.data.code) {
                case 'USER_BLOCKED':
                  errorNotification('account_blocked_message', error, false)
                  break
                case 'AUTHENTICATION_REQUIRED':
                  errorNotification('employee_auth_required', error, false)
                  break
                default:
                  errorNotification(null, error)
                  break
              }
            } else {
              errorNotification(null, error)
              resetApp(this.$trans('force_reload_message'))
            }

            resolve()
            this.isMakingPayment = false
          })
        } else {
          errorNotification(null, null, false)
          resolve()
        }
      })
    }
  }
})
