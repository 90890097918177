import axios, { AxiosInstance } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { getAccessToken } from '@/calendesk/prototypes/token'
import { refreshAuthLogic } from '@/calendesk/api/RefreshToken'

export const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 300000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  }
})

httpClient.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`
  return request
})

createAuthRefreshInterceptor(httpClient, refreshAuthLogic)
