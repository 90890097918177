import log from '@/calendesk/prototypes/log'
import { getAccessToken } from '@/calendesk/prototypes/token'
import { resetApp } from '@/calendesk/api/ResetApp'
import { httpClient } from '@/calendesk/api/HttpClient'
import { AxiosRequestConfig, Method } from 'axios'
import * as storage from '@/calendesk/tools/storage'
import { trans } from '@/calendesk/prototypes/trans'
import store from '@/store'

export function sendRequest (
  method: Method,
  url: string,
  data: Record<string, any> | null = null,
  sendWithAuth = false,
  headers: Record<string, string | number | boolean> = {},
  otherOptions: Record<string, string | number | boolean> = {}): Promise<any> {
  const tenant = store.getters['setup/getTenant'] || storage.local.getItem('tenant')
  const draftUuid = store.getters['setup/getDraftUuid'] || storage.local.getItem('draft_uuid')

  log.info('API request: ', [tenant, method, url, data, headers, otherOptions])
  if (!tenant || (sendWithAuth && !getAccessToken())) {
    if (url !== 'v2/tenants/domain') {
      resetApp(trans('force_reload_message'))
      return new Promise((resolve, reject) => {
        reject(new Error('Tenant unknown, API does not work without tenant!'))
      })
    }
  }

  const requestConfig: AxiosRequestConfig = {
    method: method,
    url: url,
    params: method === 'GET' ? data : null,
    data: method !== 'GET' ? data : null,
    headers: {
      'X-Tenant': tenant,
      'X-Draft-Uuid': draftUuid
    },
    ...otherOptions
  }

  if (sendWithAuth) {
    requestConfig.headers.Authorization = `Bearer ${getAccessToken()}`
  }

  return new Promise((resolve, reject) => {
    httpClient.request(requestConfig)
      .then(response => {
        log.info('API response: ', [tenant, method, url, data, response])
        resolve(response)
      })
      .catch(error => {
        const data = error && error.response && error.response.data ? JSON.stringify(error.response.data) : error
        log.info('API response catch: ', [url, data])

        if (url === 'auth/token/refresh' || url === 'settings/all') {
          resetApp(trans('force_reload_message'), true)
        }
        reject(error)
      })
  })
}
